import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, DataTable, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock } from '../../../components';
import { StaticImage } from 'gatsby-plugin-image';
import pmbclSafetyTable1 from "../../../assets/PMB-safety-small-V1.svg"
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Adverse Reactions in Patients With Refractory or Relapsed PMBCL | HCP`,
    keywords: `refractory or relapsed pmbcl adverse reactions`,
    description: `Learn about adverse reactions to KEYTRUDA® (pembrolizumab) in patients with refractory or relapsed primary mediastinal large B-cell lymphoma (PMBCL). See safety data from KEYNOTE-170.`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/pmbcl/","@type":"ApprovedIndication","name":"KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA is indicated for the treatment of adult and pediatric patients with refractory primary mediastinal large B-cell lymphoma (PMBCL), or who have relapsed after 2 or more prior lines of therapy. KEYTRUDA is not recommended for treatment of patients with PMBCL who require urgent cytoreductive therapy."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/pmbcl/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

// image alts - so they do not repeat in mobile & desktop
const pmbclSafetyAlt = "Most Common Adverse Reactions in KEYNOTE-170"

//from /src/util/data/indications.js
const indicationId = 21;
const jobCode = jobCodes[21].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dosing Modifications', url: '/dosing/dose-modifications/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    {label: 'Incidence of Selected Immune-Mediated Adverse Reactions'},
    {label: 'Management of Selected Immune-Mediated Adverse Reactions'},
    { label: 'Most Common Adverse Reactions in KEYNOTE&#8288;-&#8288;170' }, 
]

const KEYNOTE170dataTableFootnotes = [
    {
        label: "c.",
        text: "Graded per NCI CTCAE v4.0."
    },
    {
        label: "d.",
        text: "Includes arthralgia, back pain, myalgia, musculoskeletal pain, pain in extremity, musculoskeletal chest pain, bone pain, neck pain, non-cardiac chest pain."
    },
    {
        label: "e.",
        text: "Includes nasopharyngitis, pharyngitis, rhinorrhea, rhinitis, sinusitis, upper respiratory tract infection."
    },
    {
        label: "f.",
        text: "Includes fatigue, asthenia."
    },
    {
        label: "g.",
        text: "Includes allergic cough, cough, productive cough."
    },
];

const KEYNOTE170dataTableDefinition = '<p>NCI-CTCAE = National Cancer Institute-Common Terminology Criteria for Adverse Events; Q3W = every three weeks.<p>';

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location } >
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData />

                        <PageSection title={anchorLinkModelData[2].label}>
                            <TextBlock>
                                <p>KEYTRUDA was discontinued due to adverse reactions in 8% of 53 patients with PMBCL, and dosage was interrupted due to adverse reactions in 15% of patients. Twenty-five percent of patients had an adverse reaction requiring systemic corticosteroid therapy. Serious adverse reactions occurred in 26% of patients, and included arrhythmia (4%), cardiac tamponade (2%), myocardial infarction (2%), pericardial effusion (2%), and pericarditis (2%). Six (11%) patients died within 30 days of start of treatment.</p>
                            </TextBlock>
                            <DataTable 
                                title='Adverse Reactions in ≥20% of Patients with PMBCL'
                                footnotes={KEYNOTE170dataTableFootnotes}
                                definitions={KEYNOTE170dataTableDefinition}
                                mobileImage={() => <img src={pmbclSafetyTable1} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={pmbclSafetyAlt}/>}
                                desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/PMBCL_Keynote-170.png" alt={pmbclSafetyAlt}/>}
                                />
                        </PageSection>
                    </TemplateColumn>
                    {/* End Main Column */}

                <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
